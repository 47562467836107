exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-template-about-tsx": () => import("./../../../src/template/about.tsx" /* webpackChunkName: "component---src-template-about-tsx" */),
  "component---src-template-ai-and-data-consulting-tsx": () => import("./../../../src/template/AiAndDataConsulting.tsx" /* webpackChunkName: "component---src-template-ai-and-data-consulting-tsx" */),
  "component---src-template-artificial-intelligence-tsx": () => import("./../../../src/template/ArtificialIntelligence.tsx" /* webpackChunkName: "component---src-template-artificial-intelligence-tsx" */),
  "component---src-template-b-2-b-commerce-solutions-tsx": () => import("./../../../src/template/B2BCommerceSolutions.tsx" /* webpackChunkName: "component---src-template-b-2-b-commerce-solutions-tsx" */),
  "component---src-template-blog-details-tsx": () => import("./../../../src/template/blogDetails.tsx" /* webpackChunkName: "component---src-template-blog-details-tsx" */),
  "component---src-template-careers-tsx": () => import("./../../../src/template/Careers.tsx" /* webpackChunkName: "component---src-template-careers-tsx" */),
  "component---src-template-cares-overview-tsx": () => import("./../../../src/template/CaresOverview.tsx" /* webpackChunkName: "component---src-template-cares-overview-tsx" */),
  "component---src-template-cares-overviewe-book-download-tsx": () => import("./../../../src/template/CaresOvervieweBookDownload.tsx" /* webpackChunkName: "component---src-template-cares-overviewe-book-download-tsx" */),
  "component---src-template-contact-tsx": () => import("./../../../src/template/contact.tsx" /* webpackChunkName: "component---src-template-contact-tsx" */),
  "component---src-template-data-engineering-tsx": () => import("./../../../src/template/DataEngineering.tsx" /* webpackChunkName: "component---src-template-data-engineering-tsx" */),
  "component---src-template-data-science-tsx": () => import("./../../../src/template/DataScience.tsx" /* webpackChunkName: "component---src-template-data-science-tsx" */),
  "component---src-template-dos-and-donts-of-experience-design-tsx": () => import("./../../../src/template/dosAndDontsOfExperienceDesign.tsx" /* webpackChunkName: "component---src-template-dos-and-donts-of-experience-design-tsx" */),
  "component---src-template-drip-tsx": () => import("./../../../src/template/drip.tsx" /* webpackChunkName: "component---src-template-drip-tsx" */),
  "component---src-template-e-commerce-conversion-guide-tsx": () => import("./../../../src/template/eCommerceConversionGuide.tsx" /* webpackChunkName: "component---src-template-e-commerce-conversion-guide-tsx" */),
  "component---src-template-enquire-tsx": () => import("./../../../src/template/enquire.tsx" /* webpackChunkName: "component---src-template-enquire-tsx" */),
  "component---src-template-generative-ai-page-tsx": () => import("./../../../src/template/generativeAiPage.tsx" /* webpackChunkName: "component---src-template-generative-ai-page-tsx" */),
  "component---src-template-holiday-checklist-tsx": () => import("./../../../src/template/holidayChecklist.tsx" /* webpackChunkName: "component---src-template-holiday-checklist-tsx" */),
  "component---src-template-home-tsx": () => import("./../../../src/template/home.tsx" /* webpackChunkName: "component---src-template-home-tsx" */),
  "component---src-template-mission-thankyou-tsx": () => import("./../../../src/template/missionThankyou.tsx" /* webpackChunkName: "component---src-template-mission-thankyou-tsx" */),
  "component---src-template-moscow-prioritization-tsx": () => import("./../../../src/template/moscowPrioritization.tsx" /* webpackChunkName: "component---src-template-moscow-prioritization-tsx" */),
  "component---src-template-mvp-template-tsx": () => import("./../../../src/template/mvpTemplate.tsx" /* webpackChunkName: "component---src-template-mvp-template-tsx" */),
  "component---src-template-mvp-webinar-tsx": () => import("./../../../src/template/MVPWebinar.tsx" /* webpackChunkName: "component---src-template-mvp-webinar-tsx" */),
  "component---src-template-phase-1-cares-framework-tsx": () => import("./../../../src/template/Phase1CaresFramework.tsx" /* webpackChunkName: "component---src-template-phase-1-cares-framework-tsx" */),
  "component---src-template-phase-1-e-book-download-tsx": () => import("./../../../src/template/Phase1eBookDownload.tsx" /* webpackChunkName: "component---src-template-phase-1-e-book-download-tsx" */),
  "component---src-template-phase-2-cares-framework-tsx": () => import("./../../../src/template/Phase2CaresFramework.tsx" /* webpackChunkName: "component---src-template-phase-2-cares-framework-tsx" */),
  "component---src-template-phase-2-e-book-download-tsx": () => import("./../../../src/template/Phase2eBookDownload.tsx" /* webpackChunkName: "component---src-template-phase-2-e-book-download-tsx" */),
  "component---src-template-phase-3-cares-framework-tsx": () => import("./../../../src/template/Phase3CaresFramework.tsx" /* webpackChunkName: "component---src-template-phase-3-cares-framework-tsx" */),
  "component---src-template-phase-3-e-book-download-tsx": () => import("./../../../src/template/Phase3eBookDownload.tsx" /* webpackChunkName: "component---src-template-phase-3-e-book-download-tsx" */),
  "component---src-template-phase-4-cares-framework-tsx": () => import("./../../../src/template/Phase4CaresFramework.tsx" /* webpackChunkName: "component---src-template-phase-4-cares-framework-tsx" */),
  "component---src-template-phase-4-e-book-download-tsx": () => import("./../../../src/template/Phase4eBookDownload.tsx" /* webpackChunkName: "component---src-template-phase-4-e-book-download-tsx" */),
  "component---src-template-pp-cb-2-bd-2-c-page-tsx": () => import("./../../../src/template/PPCb2bd2cPage.tsx" /* webpackChunkName: "component---src-template-pp-cb-2-bd-2-c-page-tsx" */),
  "component---src-template-pp-cbigcommerce-partner-agency-tsx": () => import("./../../../src/template/PPCbigcommercePartnerAgency.tsx" /* webpackChunkName: "component---src-template-pp-cbigcommerce-partner-agency-tsx" */),
  "component---src-template-pp-chire-laravel-developers-tsx": () => import("./../../../src/template/PPChireLaravelDevelopers.tsx" /* webpackChunkName: "component---src-template-pp-chire-laravel-developers-tsx" */),
  "component---src-template-pp-chire-tsx": () => import("./../../../src/template/PPChire.tsx" /* webpackChunkName: "component---src-template-pp-chire-tsx" */),
  "component---src-template-ppc-hire-magento-developer-tsx": () => import("./../../../src/template/PPCHireMagentoDeveloper.tsx" /* webpackChunkName: "component---src-template-ppc-hire-magento-developer-tsx" */),
  "component---src-template-ppc-hire-shopify-developer-tsx": () => import("./../../../src/template/PPCHireShopifyDeveloper.tsx" /* webpackChunkName: "component---src-template-ppc-hire-shopify-developer-tsx" */),
  "component---src-template-privacy-policy-tsx": () => import("./../../../src/template/privacyPolicy.tsx" /* webpackChunkName: "component---src-template-privacy-policy-tsx" */),
  "component---src-template-process-tsx": () => import("./../../../src/template/process.tsx" /* webpackChunkName: "component---src-template-process-tsx" */),
  "component---src-template-retail-development-tsx": () => import("./../../../src/template/RetailDevelopment.tsx" /* webpackChunkName: "component---src-template-retail-development-tsx" */),
  "component---src-template-service-b-2-bb-2-c-websites-tsx": () => import("./../../../src/template/serviceB2BB2CWebsites.tsx" /* webpackChunkName: "component---src-template-service-b-2-bb-2-c-websites-tsx" */),
  "component---src-template-service-digital-commerce-tsx": () => import("./../../../src/template/serviceDigitalCommerce.tsx" /* webpackChunkName: "component---src-template-service-digital-commerce-tsx" */),
  "component---src-template-service-digital-products-tsx": () => import("./../../../src/template/serviceDigitalProducts.tsx" /* webpackChunkName: "component---src-template-service-digital-products-tsx" */),
  "component---src-template-service-scale-tsx": () => import("./../../../src/template/serviceScale.tsx" /* webpackChunkName: "component---src-template-service-scale-tsx" */),
  "component---src-template-services-tsx": () => import("./../../../src/template/services.tsx" /* webpackChunkName: "component---src-template-services-tsx" */),
  "component---src-template-sitemap-tsx": () => import("./../../../src/template/sitemap.tsx" /* webpackChunkName: "component---src-template-sitemap-tsx" */),
  "component---src-template-staff-augmentation-tsx": () => import("./../../../src/template/staffAugmentation.tsx" /* webpackChunkName: "component---src-template-staff-augmentation-tsx" */),
  "component---src-template-technology-page-tsx": () => import("./../../../src/template/technologyPage.tsx" /* webpackChunkName: "component---src-template-technology-page-tsx" */),
  "component---src-template-thank-you-tsx": () => import("./../../../src/template/thankYou.tsx" /* webpackChunkName: "component---src-template-thank-you-tsx" */),
  "component---src-template-thankyou-dos-experience-design-tsx": () => import("./../../../src/template/thankyouDosExperienceDesign.tsx" /* webpackChunkName: "component---src-template-thankyou-dos-experience-design-tsx" */),
  "component---src-template-thankyou-moscow-prioritization-tsx": () => import("./../../../src/template/thankyouMoscowPrioritization.tsx" /* webpackChunkName: "component---src-template-thankyou-moscow-prioritization-tsx" */),
  "component---src-template-thankyou-mvp-tsx": () => import("./../../../src/template/thankyouMvp.tsx" /* webpackChunkName: "component---src-template-thankyou-mvp-tsx" */),
  "component---src-template-thankyou-staff-augmentation-tsx": () => import("./../../../src/template/thankyouStaffAugmentation.tsx" /* webpackChunkName: "component---src-template-thankyou-staff-augmentation-tsx" */),
  "component---src-template-thankyoue-commerce-holiday-checklist-tsx": () => import("./../../../src/template/thankyoueCommerceHolidayChecklist.tsx" /* webpackChunkName: "component---src-template-thankyoue-commerce-holiday-checklist-tsx" */),
  "component---src-template-thoughtspace-tsx": () => import("./../../../src/template/thoughtspace.tsx" /* webpackChunkName: "component---src-template-thoughtspace-tsx" */),
  "component---src-template-unlocking-power-of-ai-tsx": () => import("./../../../src/template/unlockingPowerOfAI.tsx" /* webpackChunkName: "component---src-template-unlocking-power-of-ai-tsx" */),
  "component---src-template-work-details-page-tsx": () => import("./../../../src/template/WorkDetailsPage.tsx" /* webpackChunkName: "component---src-template-work-details-page-tsx" */),
  "component---src-template-work-tsx": () => import("./../../../src/template/Work.tsx" /* webpackChunkName: "component---src-template-work-tsx" */)
}

