import React from "react";
import fetch from "cross-fetch";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from "@apollo/client";
import $ from "jquery";
import { ParallaxProvider } from "react-scroll-parallax";

export const onInitialClientRender = () => {
  //thank you page description tag charecter limit jquery
  const postBoxes = document.querySelectorAll(".post_box");
  postBoxes.forEach((postBox) => {
    const descriptionTag = postBox.querySelector(".description p");
    if (descriptionTag) {
      const text = descriptionTag.textContent;
      if (text.length > 117) {
        descriptionTag.textContent = text.substring(0, 118) + " ...";
      }
    }
  });

  $(function () {
    $(".selected-flag .flag").addClass("us");
    // Hire page schedule an interview form jquery
    document
      .querySelectorAll(".open-schedule-popup")
      .forEach(function (element) {
        element.addEventListener("click", function (e) {
          setTimeout(function () {
            var dev_title = element.getAttribute("data-dev");
            document.querySelector(".ginput_container_text #input_16").value =
              dev_title;
          }, 1000);
        });
      });

    // Hire page hire now form jquery
    $(document).on("click", ".form_modal_button", function (e) {
      setTimeout(() => {
        //for selected skills
        $(".popup-skills-wrap .skills-btn").on("click", function () {
          $(this).toggleClass("skills-selected");
          var skills = $(this).attr("data-is-selected");
          if (skills === "true") {
            $(this).attr("data-is-selected", "");
          } else {
            $(this).attr("data-is-selected", "true");
          }

          var dev_skills = [];
          $(".popup-skills-wrap .skills-btn").each(function () {
            var skills_selected = $(this).attr("data-is-selected");
            if (skills_selected === "true") {
              var skills_name = $(this).find(".skills-title").text();
              dev_skills.push(skills_name);
            }
          });

          var final_selected_skills = dev_skills.join(", ");
          $(".ginput_container_text #input_10").val(final_selected_skills);
        });

        // Hire page hire now form validation
        $("#gform_submit_button_17").on("click", function (e) {
          var skills = $(".ginput_container_text #input_10").val();

          if (!$.trim(skills).length) {
            var other_skills = $("li.other-skills input").val();
            if (!$.trim(other_skills).length) {
              var alert_message = $("li.other-skills").find("span").length;
              if (alert_message === 0) {
                $("li.other-skills").append(
                  '<span role="alert" class="gravityform__error_message gfield_description validation_message">This field is required.</span>'
                );
                e.preventDefault();
                return false;
              } else {
                $("li.other-skills span").text("This field is required.");
                e.preventDefault();
                return false;
              }
            } else {
              var alert_message_1 = $("li.other-skills").find("span").length;
              if (alert_message_1 > 0) {
                $("li.other-skills").find("span").remove();
              }
            }
          } else {
            var alert_message_2 = $("li.other-skills").find("span").length;
            if (alert_message_2 > 0) {
              $("li.other-skills").find("span").remove();
            }
          }
        });
      }, 1000);
    });
  });

  //scroll animation jquery
  $(document).on("scroll", function (e) {
    $.each($("section"), function (index, section) {
      if (
        $(this).scrollTop() >= section.getBoundingClientRect().top &&
        $(this).scrollTop() <= section.getBoundingClientRect().bottom
      ) {
        if ($(section).hasClass("dark")) {
          $("header").removeClass("black");
          $("header").addClass("white");
          $("#talk_button").removeClass("head-button");
          $("#talk_button").addClass("head-button2");
        } else {
          $("header").removeClass("white");
          $("header").addClass("black");
          $("#talk_button").removeClass("head-button head-button2");
          $("#talk_button").addClass("head-button");
        }
      }
    });

    // blog details page bottom let's talk sticky button script
    var button = $(".LetsTalkStickyBtn");
    if (button.length > 0) {
      var buttonOffset = button.offset();
      var buttonTop = buttonOffset.top;
      var buttonBottom = buttonTop + button.outerHeight();

      $.each($("section"), function (index, section) {
        var section = $(this);
        var sectionOffset = section.offset();
        var sectionTop = sectionOffset.top;
        var sectionBottom = sectionTop + section.outerHeight();

        if (
          (buttonTop >= sectionTop && buttonTop <= sectionBottom) ||
          (buttonBottom >= sectionTop && buttonBottom <= sectionBottom) ||
          (buttonTop <= sectionTop && buttonBottom >= sectionBottom)
        ) {
          button.toggleClass("dark_section", section.hasClass("dark"));
        }
      });
    }
  });
};

export const wrapRootElement = ({ element }) => {
  const client = new ApolloClient({
    link: new HttpLink({
      uri: "https://backend.techtic.com/index.php?graphql",
      fetch,
      fetchOptions: {
        mode: "no-cors",
      },
    }),
    cache: new InMemoryCache(),
  });

  return (
    <ParallaxProvider>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </ParallaxProvider>
  );
};
